:root {
    --gradientFromColor: "#C1272D";
    --gradientToColor: "#F15A22";
    --primaryBackgroundColor: "#FFFFFF";
    --primaryHeaderBackgroundColor: "#FFFFFF";
    --primaryFooterBackgroundColor: "#FFFFFF";
    --cardBackgroundColor: "#FFFFFF";
    --cardTextColor: "#000000";
    --bankCardTextColor: "#000000";
    --primaryColor: "#F15A22";
    --primaryIconColor: "#F15A22";
    --primaryTextColor: "#FFFFFF";
    --buttonTextColor: "#FFFFFF";
    --buttonDisabledColor: "#8091A5";
    --buttonHoverFocusTextColor: "#000000";
    --linkHoverFocusTextColor: "#000000";
    --controlLabelColor: "#8091A5";
    --controlBackgroundColor: "rgba(241, 90, 34, 0.02)";
    --disabledCardBackgroundColor: "#b3b3b3";
}

:export {
    primaryColor: var(--primaryColor);
    primaryIconColor: var(--primaryIconColor);
    primaryTextColor: var(--primaryTextColor);
    primaryBackgroundColor: var(--primaryBackgroundColor);
    primaryHeaderBackgroundColor: var(--primaryHeaderBackgroundColor);
    primaryFooterBackgroundColor: var(--primaryFooterBackgroundColor);
    cardBackgroundColor: var(--cardBackgroundColor);
    cardTextColor: var(--cardTextColor);
    bankCardTextColor: var(--bankCardTextColor);
    gradientFromColor: var(--gradientFromColor);
    gradientToColor: var(--gradientToColor);
    buttonTextColor: var(--buttonTextColor);
    buttonDisabledColor: var(--buttonDisabledColor);
    buttonHoverFocusTextColor: var(--buttonHoverFocusTextColor);
    linkHoverFocusTextColor: var(--linkHoverFocusTextColor);
    controlLabelColor: var(--controlLabelColor);
    controlBackgroundColor: var(--controlBackgroundColor);
    disabledCardBackgroundColor: var(--disabledCardBackgroundColor);
}