/*!

 =========================================================
 * Eclipse PWA - v1.9.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: https://www.ukheshe.com/product/eclipse
 * Copyright 2020 Ukheshe Technology (https://www.ukheshe.com)
 * Licensed under MIT (https://ukheshe.comtimofficial/eclipse/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import url(../../style.scss);

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: var(--primaryBackgroundColor) !important;
  color: var(--primaryTextColor) !important;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.25em !important;
  margin: 0;
  font-weight: bold !important;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #333;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  /* background: #fff; */
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ef5822;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #000;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  /* background-color: #fff; */
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

* {
  letter-spacing: normal !important;
}

.header-menu .headerMenu .svg .svg-hover, .footer-menu li a .svg .svg-hover {
  stroke: #fff;
}

.header-menu .headerMenu .svg .svg-hover-fill, .footer-menu li a .svg .svg-hover-fill {
  fill: #fff;
}

.header-menu .headerMenu:hover .svg .svg-hover, .header-menu .headerMenu.active .svg .svg-hover, .footer-menu li a:hover .svg .svg-hover, .footer-menu li a.active .svg .svg-hover  {
  stroke: var(--primaryColor);
}

.header-menu .headerMenu:hover .svg .svg-hover-fill, .header-menu .headerMenu.active .svg .svg-hover-fill, .footer-menu li a:hover .svg .svg-hover-fill, .footer-menu li a.active .svg .svg-hover-fill {
  fill: var(--primaryColor);
}

/*Parth Css*/
.m-t-0 {
  margin-top: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.m-b-10 {
  margin-bottom: 10px;
}

.auth-title{line-height: 46px !important; font-size: 40px;}
.internal-wrap .MuiRadio-colorSecondary.Mui-checked{color: var(--primaryColor);}
.internal-wrap .MuiSwitch-colorSecondary.Mui-checked{color: var(--primaryColor);}
.internal-wrap .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{background-color: var(--primaryColor);}
.MuiRadio-colorSecondary.Mui-checked{color: var(--primaryColor) !important;}
.MuiCheckbox-colorSecondary.Mui-checked{color: var(--primaryColor) !important;}
.identity-doc-radio label{color: #000;}

#Dashboard, #Wallet, .auth-parent{position: relative;}
#Dashboard .page-title{text-align: center;}
#Dashboard .page-title, #Wallet .page-title{position: relative; z-index: 1;}
.page-title{margin-top: 5px; margin-bottom: 15px;}
#Dashboard .curve-area, #Wallet .curve-area, .auth-parent .curve-area{display: block;}
.curve-area{ margin: 0px; padding: 0px; float: left; width: 100%; position: absolute; left: 0; top: 0; transform: translateY(-73%); overflow: hidden; display: none;}
.curve{width: 800px; left: 50%; position: relative; height: 530px;
  border-radius: 100%; transform: translateX(-50%);}

  .header-menu{float: right;}
  .header-menu .headerMenu{background: transparent !important; color:#fff;  text-decoration: none; cursor: pointer;}
  .header-menu .headerMenu:hover, .header-menu .headerMenu.active{color: var(--primaryColor); text-decoration: none;}
  .header-menu li {padding-left: 16px; padding-right: 16px;}
  .footer-menu{float: left; width: 100%; text-align: center; display: none;}
  .footer-part{float: left; width: 100%;}
  .footer-menu li{width:calc(100%/4);}
  .footer-menu li a{padding: 10px 5px; color: #fff; text-decoration: none; cursor: pointer;}
  .footer-menu li a:hover, .footer-menu li a.active{color:var(--primaryColor); text-decoration: none;}

  #Dashboard .internal-wrap, #Wallet .internal-wrap{margin: 0 auto; max-width: 500px; width: 100%; position: relative; z-index: 1;}
  #Dashboard .internal-card, #Wallet .internal-card{width: 100%; background: #fff; border-radius: 6px; box-shadow: 0 0 14px 0 rgba(128,145,165,0.3); padding-bottom: 15px;}

  .internal-wrap{margin: 0 auto; max-width: 500px; width: 100%; position: relative; z-index: 1;}
  .internal-wrap.auth-internal-wrap{max-width: 750px;}
  .internal-wrap.auth-internal-wrap .internal-card{width: 100%; padding: 0 160px 15px; height: auto; background: #fff; border-radius: 6px; box-shadow: 0 0 14px 0 rgba(128,145,165,0.3); box-sizing: border-box;}
  .internal-card{width: 100%;}

.wallet-list .bottom-content{margin: 0px; padding: 0px; float: left; width: 100%; }
.wallet-list .wallet-id{padding: 0 0 0 32px; float: left; width: 100%; text-align: left; margin-top: 5px; box-sizing: border-box;}
.wallet-list .bottom-content ul{margin: 0px; padding: 0 0 0 32px; float: left; width: 100%; list-style: none; box-sizing: border-box;}
.wallet-list .bottom-content ul li{margin: 10px 0 0 0; padding: 0px; float: left; width: 100%; text-align: left;}
.wallet-list .bottom-content ul li span{float: left; max-width: 48%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.wallet-list .bottom-content ul li strong{float: right; max-width: 48%;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}

.image-item:nth-child(1){padding: 30px 0px 37px 0px;}
.image-item > div:nth-child(1){padding: 0px 0px 0px 0px; margin-right: 20px;}
.image-item{padding: 20px 0px 20px 0px;}
.add-card-placeholder{display: flex; justify-content: center; vertical-align: center; height: 100%; border:2px dashed var(--primaryColor); box-sizing: border-box; border-radius: 25px;}
.card-holder{position: relative;}
.card-content{ margin: 0px; padding: 0 40px; float: left; width: 100%; position: absolute; left: 0; bottom: 35%; box-sizing: border-box;}
.card-content h5, .card-content h6{margin: 0px; padding: 3px 0; float: left; width: 100%; font-size: 15px; color: var(--bankCardTextColor); line-height: 17px; font-weight: 600; text-transform: uppercase;}
.card-content ul{margin: 20px 0 0 0; padding: 0px; float: left; width: 100%;}
.card-content li{margin: 0px; padding: 0px; float: left; width: 50%; list-style: none;}
.card-content li span{margin: 0px; padding: 4px 0 0 0; float: left; width: auto; font-size: 11px; color: var(--bankCardTextColor); line-height: 11px;}
.card-content li strong{margin: 0px; padding: 0 0 0 10px; float: left; width: auto; font-size: 15px; font-weight: 600; color: var(--bankCardTextColor);}
.card {position: relative;}
span.cardType {position: absolute;top: 30px;right: 30px;left: auto;color: var(--primaryBackgroundColor);font-size: 16px;font-weight: bold;}
.qr-scan-area section > div{box-shadow: none !important; border: 70px solid rgba(0, 0, 0, 0.6) !important; width: calc(100% - -128px) !important;
  height: calc(100% - -25px) !important; transform: translate(-64px, -13px);}
@media screen and (max-width: 460px) {
  .qr-scan-area section > div{ width: calc(100% - -130px) !important; height: calc(100% - -42px) !important; transform: translate(-65px, -21px);}
  .footer-menu.commuter-menu li a { padding: 10px 11px;}
}
.qr-scan-area{position: relative;overflow: hidden; margin:auto}
.qr-scan-area > img{width: 72% !important; position: absolute; z-index: 1; top: 0; left: 0; right: 0; bottom: 0; margin: auto;}

.docBox{border:2px solid gray; text-align: center; padding: 10px; min-height: 6em; max-height: 6em;}
.docBox img{display: inline-block; vertical-align: middle; max-width: 100%; max-height: 6em;}

.transaction-list .list-title{color: var(--primaryBackgroundColor);background-color: var(--primaryHeaderBackgroundColor); width: 100%; padding: 0.5em 1em; box-sizing: border-box;}
.transaction-list ul{margin:0.5em 0em; width: 100%;}
.transaction-list ul li{padding: 0.5em 1em; border-bottom: 1px solid #DFE6EE;}
.transaction-list ul li:last-child{border: none;}
.transaction-list ul .left-content{color: #1c3e45; max-width: 60%;}

.linked-card-list li{margin-bottom: 20px;}
.linked-card-list li:last-child{margin-bottom: 0;}
.linked-card-list li .content{margin: 0 10px;}

.progress-bar{float: left; width: 100%; margin: -10px 0 0 0;}
.progress-bar > small{font-weight: 400;}
.progress-bar strong{margin: 0px; padding: 0px; float: left; width: 100%; height: 4px; position: relative; background-color: #DFE6EE; border-radius: 100px; border:1px solid transparent; overflow: hidden;}
.progress-bar strong small{float:left; position: absolute; left: 0; height: 4px; background-color: var(--primaryColor);  border-radius: 100px;}

.roundButton{padding-left: 18px !important; padding-right: 18px !important; width: auto !important; border-radius: 100% !important;}
.selfie-area{overflow: hidden; position: relative; width: 100%;}
.selfie-area video{top: 0px; left: 0px; display: block; position: absolute; overflow: hidden; width: 100%; height: 100%; object-fit: cover;}
.selfie-area.document-area video{top: 9px;}

.MuiTabs-root .MuiTabs-indicator {
  background-color: var(--primaryTextColor);
  height: 0;
}

.MuiFormLabel-root{
  color: var(--controlLabelColor) !important;
  line-height: 1.4 !important;
}

.MuiSelect-iconFilled,
.MuiFilledInput-root .MuiFilledInput-input {
  color: var(--primaryTextColor) !important;
}

.MuiFilledInput-root .MuiFilledInput-input:focus {
  color: var(--primaryColor) !important;
  background-color: var(--controlBackgroundColor);
}

.MuiButton-root {
  text-transform: inherit !important;
}

.MuiIconButton-label {
  color: var(--primaryColor);
}

.MuiSwitch-root .MuiIconButton-label {
  color: inherit;
}

.MuiTabs-root .MuiTab-textColorPrimary.Mui-selected {
  color: var(--primaryTextColor);
  background: var(--primaryColor);
}

.MuiTabs-flexContainer {
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
}

.external-svglink:hover .svg .svg-hover{fill: var(--linkHoverFocusTextColor);}

.web-footer{background-color: var(--primaryHeaderBackgroundColor); position: fixed; bottom: 0; left: 0; width: 100%; z-index: 1;}
.webfooter-content{ width: 100%; display: flex; flex-direction: row; align-items: center;}
.web-footer p{color: #fff; font-weight: 500; font-size: 12px; line-height: 18px;}
.web-footer ul{display: flex; align-self: flex-end; margin-inline-start: auto; list-style: none;}
.web-footer ul li a{color: #fff; font-weight: 500; font-size: 12px; line-height: 18px; padding: 0 10px;}
.web-footer ul li a:hover{color: var(--linkHoverFocusTextColor);}

/*---------------*/
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 21px 4px, rgb(0 0 0 / 23%) 0px 1px 4px 1px, rgb(0 0 0 / 24%) 0px 1px 4px 2px;
}
.MuiPopover-root[role='presentation'] {z-index: 9999 !important;}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper a.headerMenu{color: var(--primaryTextColor);}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper a.headerMenu:hover{color: var(--primaryColor);}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
#Wallet .Wallet {
  display: none;
}
#reasonType label {
  color: var(--primaryTextColor)
}

.fourDigitInput > div{padding: 5px 0;}
.MuiCheckbox-root .MuiSvgIcon-root{width: 26px; height: 26px;}

  @media screen and (max-width:1200px) {
    .footer-menu{display:block;}
    #Dashboard, #Wallet{ max-height: calc(100vh - 175px); min-height: calc(100vh - 175px); overflow: auto;}
    .footer-part{display: none;}
    .header-menu{display: none;}
    .web-footer{display: none;}
  }
  @media screen and (max-width: 1024px) {
    .auth-parent .curve-area{display: none;}
    .internal-wrap.auth-internal-wrap{max-width: 500px;}
    .internal-wrap.auth-internal-wrap .internal-card{padding: 0; box-shadow: inherit; background: transparent;}
    .fourDigitInput > div{padding: 10px 0;}
    .ratify-success-card{margin-top: 0px; padding-bottom: inherit !important;}
    .internal-wrap.auth-internal-wrap .internal-card > div{margin-top: 0;}
  }

  @media screen and (min-width: 1024px) {
    .curve-area{transform: translateY(-65%); }
    .curve{height: 800px;}
    .ratify-success-card{margin-top: 76px; padding-bottom: 50px !important;}
  }
  @media screen and (min-width: 600px) {
    .curve{width: calc(100% - -40%);}
  }
  @media screen and (max-width: 500px) {
    .card-content{top: inherit; bottom: 25%; padding: 0 10%;}
    .fourDigitInput > div{padding: 5px 0;}
  }
  .card-content.vleis-card {bottom: 15%;left: 10px;}

  .card-content.vleis-card * {
      color: #fff;
  }
.MuiPaper-rounded{width: 300px;}
.link-color{
  color: var(--primaryTextColor);
  cursor: pointer;
  font-weight: 400
}
.crossIcon:hover svg{color: var(--primaryTextColor);
  cursor: pointer;}

div#PayBill .internal-wrap {
    max-width: 930px;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
} 

.slick-track {
  display: flex;
  align-items: center;
}
.slide-leftArrow {
  position: absolute;
  left: 6px;
  top: 2px;
}
.slide-rightArrow {
  position: absolute;
  right: 6px;
  top: 2px;
}
